/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable no-unreachable */
/* eslint-disable no-use-before-define */
import Vue from 'vue'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'

export default function useCaregiverEventHandler(props, clearForm, emit, refreshTableTrigger = null) {
  const initialCaregiverData = ref({})
  const caregiverData = ref({})

  const idfile = ref([])

  const signatureFile = ref([])

  const profilepictureFile = ref([])

  const covid19vaccinecardFile = ref([])

  const idImageToDisplay = ref([])

  const idDocuments = ref([])

  const profilePics = ref([])

  const vaxCards = ref([])

  const btnDisabled = ref(false)

  const isCaregiverHandlerSidebarActive = ref(computed(() => store.state.caregiver.isCaregiverHandlerSidebarActive))

  const sexOptions = [
    'Male',
    'Female',
  ]

  const maritalStatusOptions = [
    'Single',
    'Married',
    'Widowed',
    'Divorced',
    'Separated',
    'Registered Partnership',
  ]

  const eyeColorOptions = [
    'Brown',
    'Blue',
    'Hazel',
    'Amber',
    'Gray',
    'Green',
  ]

  const hairColorOptions = [
    'Black',
    'Brown',
    'Blonde',
    'Auburn',
    'Red',
    'Chestnut',
    'Gray',
    'White',
  ]

  const statusOptions = ref([
    'Active',
    // 'Applicant',
    'Inactive',
    // 'New',
    // 'Pending',
    'Resigned',
    'Terminated',
  ])

  const idTypeOptions = [
    'ID',
    'Driver\'s License',
  ]

  const stateOptions = [
    'AK',
    'AL',
    'AR',
    'AS',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'GU',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VI',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
  ]

  const flatPickrConfig = {
    dateFormat: 'Y-m-d',
  }

  const viewDocu = url => {
    window.open(url)
  }

  const deleteFile = param => {
    const { file, column } = param
    const data = {}

    data.id = caregiverData.value.id
    data.action = 'upload-file'
    data['sub-action'] = 'delete'

    if (column === 'iddocument') {
      data.column = column
      data.file = file.value
      data['old-files'] = idDocuments.value.filter(item => item !== file).map(f => f.value)
      deleteRequest(data)
    }
    // if (column === 'profilepicture') {
    //   data.column = column
    //   data.file = profilePics.value.length > 0 ? profilePics.value.map(f => f.value) : ''
    //   data['old-files'] = []
    //   data.file !== '' ? deleteRequest(data) : ''
    // }
    if (column === 'covid19vaccinecard') {
      data.column = column
      data.file = file.value
      data['old-files'] = vaxCards.value.filter(item => item !== file).map(f => f.value)
      data.file !== '' ? deleteRequest(data) : ''
    }

    // console.log('data', data)
  }

  const deleteRequest = data => {
    const formData = new FormData()
    formData.append('data', JSON.stringify(data))

    store.dispatch('caregiver/request', formData)
      .then(response => {
        handleResponse(response.data.response.data)
        // emit('toast-notif', 'Success', `${data.file} Deleted!`, 'CheckIcon', 'success')
      })
      .catch(error => {
        // console.log(error)
        emit('toast-notif', 'Error', '', 'XIcon', 'danger')
      })
  }

  // format files
  const formatFiles = (files, id) => {
    const temp = []
    if (files) {
      files.forEach(file => {
        const root = `${Vue.prototype.$mainUrl}upload/${id}/`
        temp.push({
          filename: file,
          value: file,
          url: `${root + file}`,
          fromDb: true,
        })
      })
    }

    return temp
  }

  const setCaregiverData = () => {
    idDocuments.value = formatFiles(caregiverData.value.iddocument, caregiverData.value.id)
    vaxCards.value = formatFiles(caregiverData.value.covid19vaccinecard, caregiverData.value.id)
  }
  // updateCaregiver
  const updateCaregiver = caregiverData1 => {
    const formData = new FormData()

    const data = JSON.stringify({
      action: 'update',
      data: caregiverData1.data,
    })
    formData.append('data', data)

    store.dispatch('caregiver/request', formData)
      .then(() => {
        // isCaregiverHandlerSidebarActive.value = false
        store.commit('caregiver/SET_isCaregiverHandlerSidebarActive', false)
        store.commit('caregiver/SET_caregiver', caregiverData1.data)
        const ind = store.state.caregiver.items.findIndex(item => item.id === caregiverData1.data.id)
        if (ind > -1) {
          store.commit('caregiver/SET_ITEM', { id: ind, data: caregiverData1.data })
        }
        if (typeof refreshTableTrigger === 'function') {
          refreshTableTrigger()
        }
        // store.dispatch('caregiver/getCaregivers')
        // toastNotif('Success', 'Updated successfully!', 'CheckIcon', 'success')
        store.dispatch('caregiver/popupNotif', {
          icon: 'success',
          title: 'Saved!',
          text: 'Updated succesfully.',
          showConfirmButton: false,
          timer: 1500,
        })
      })
      .catch(error => {
        console.log(error)
        // toastNotif('Error', '', 'AlertTriangleIcon', 'danger')
        store.dispatch('caregiver/popupNotif', {
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: false,
          timer: 1500,
        })
      })
  }

  const onSubmit = () => {
    const caregiverInsertData = { data: caregiverData.value }
    // emit('update-caregiver', caregiverInsertData)
    updateCaregiver(caregiverInsertData)
  }

  watch(idfile, files => {
    if (files.length > 0) {
      const column = 'iddocument'
      const tempDocuOld = idDocuments.value ? idDocuments.value.map(f => f.value) : []
      const toDelete = null

      uploadFile({
        column, tempDocuOld, files, toDelete,
      })
    }
  })

  // watch(profilepictureFile, files => {
  //   console.log('files', files === {})
  //   if (files !== {}) {
  //     const column = 'profilepicture'
  //     const tempDocuOld = profilePics.value ? profilePics.value.map(f => f.value) : []
  //     deleteFile({ file: files, column })
  //     uploadFile({ column, tempDocuOld, files })
  //   }
  // })
  watch(covid19vaccinecardFile, files => {
    if (files instanceof File) {
      // console.log('files', files.name)
      const column = 'covid19vaccinecard'
      const tempDocuOld = []
      const toDelete = vaxCards.value.length > 0 ? vaxCards.value.map(f => f.value) : null
      uploadFile({
        column, tempDocuOld, files, toDelete,
      })
    }
  })

  const handleResponse = response => {
    /* Enables btn */
    btnDisabled.value = false

    idDocuments.value = response.column === 'iddocument' ? formatFiles(response.data, caregiverData.value.id) : idDocuments.value
    vaxCards.value = response.column === 'covid19vaccinecard' ? formatFiles(response.data, caregiverData.value.id) : caregiverData.value.covid19vaccinecard
  }

  const uploadFile = param => {
    const {
      column, tempDocuOld, files, toDelete,
    } = param

    /* Disables btn */
    btnDisabled.value = true

    if (files) {
      const formData = new FormData()

      const data = JSON.stringify({
        action: 'upload-file',
        id: caregiverData.value.id,
        column,
        'old-files': tempDocuOld,
        'to-delete': toDelete || null,
      })
      // console.log('data', data)
      if (column === 'iddocument') {
        files.forEach((file, index) => {
          formData.append(`files[${index}]`, file)
        })
      } else {
        formData.append('files[0]', files)
      }

      formData.append('data', data)

      store.dispatch('caregiver/request', formData)
        .then(response => {
          handleResponse(response.data.response.data)
          if (column === 'iddocument') {
            idfile.value = []
            files.forEach(f => {
              emit('toast-notif', 'Success', `${f.name} added!`, 'CheckIcon', 'success')
            })
          } else {
            covid19vaccinecardFile.value = []
            emit('toast-notif', 'Success', `${files.name} added!`, 'CheckIcon', 'success')
          }
        })
        .catch(error => {
          /* Enables btn */
          btnDisabled.value = false
          // console.log(error)
          emit('toast-notif', 'Error', '', 'XIcon', 'danger')
        })
    }
  }

  // * Clear form if sidebar is closed and set caregiver data when open
  watch(isCaregiverHandlerSidebarActive, val => {
    clearForm() /* remove class validation */
    const appContainer = document.querySelector('#app')
    if (!val) {
      resetValues() // empty id file arrays
      caregiverData.value = {}
      initialCaregiverData.value = {}
      // store.dispatch('caregiver/getCaregivers')
      appContainer.style.overflow = ''
    } else {
      caregiverData.value = JSON.parse(JSON.stringify(store.state.caregiver.caregiver))
      setCaregiverData()
      setTimeout(() => {
        initialCaregiverData.value = JSON.parse(JSON.stringify(caregiverData.value))
      }, 2000)
      appContainer.style.overflow = 'hidden'
    }
  })

  const resetValues = () => {
    idfile.value = []
    profilepictureFile.value = []
    covid19vaccinecardFile.value = []
  }

  /* eslint-disable */
  const getObjectDifferences = (obj1, obj2) => {
    return Object.keys(obj1).reduce((differences, key) => {
      if (obj1[key] !== obj2[key]) {
        differences[key] = obj2[key]
      }
      return differences
    }, {})
  }
  /* eslint-enable */

  const noChanges = ref(computed(() => {
    // console.log('phew', JSON.stringify(initialCaregiverData.value) === JSON.stringify(caregiverData.value), getObjectDifferences(initialCaregiverData.value, caregiverData.value))
    if (JSON.stringify(initialCaregiverData.value) === JSON.stringify(caregiverData.value)) {
      return true
    }

    return false
  }))
  return {
    noChanges,
    initialCaregiverData,
    caregiverData,
    idfile,
    signatureFile,
    profilepictureFile,
    covid19vaccinecardFile,
    idImageToDisplay,
    idDocuments,
    profilePics,
    vaxCards,
    isCaregiverHandlerSidebarActive,
    btnDisabled,

    // functions
    getObjectDifferences,
    setCaregiverData,
    onSubmit,
    viewDocu,
    deleteFile,

    // options
    sexOptions,
    maritalStatusOptions,
    eyeColorOptions,
    hairColorOptions,
    idTypeOptions,
    flatPickrConfig,
    statusOptions,
    stateOptions,
  }
}
