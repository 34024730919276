<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-caregiver"
      sidebar-class="sidebar-lg"
      :visible="isCaregiverHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template>
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            {{ caregiverData.id == null ? "Add" : "Update" }} Caregiver
          </h5>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
          > <!-- @reset.prevent="resetForm" -->
            <!-- Last Name -->
            <validation-provider
              #default="validationContext"
              name="Last Name"
              rules="required"
            >
              <b-form-group
                label="Last Name *"
                label-for="lname"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="lname"
                  v-model="caregiverData.lastname"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Middle Name -->
            <validation-provider
              #default="validationContext"
              name="Middlename"
            >
              <b-form-group
                label="Middle Name"
                label-for="mname"
              >
                <b-form-input
                  id="mname"
                  v-model="caregiverData.middlename"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- First Name -->
            <validation-provider
              #default="validationContext"
              name="First Name"
              rules="required"
            >
              <b-form-group
                label="First Name *"
                label-for="name"
              >
                <b-form-input
                  id="fname"
                  v-model="caregiverData.firstname"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- AKA Last Name -->
            <validation-provider
              #default="validationContext"
              name="AKA Last Name"
            >
              <b-form-group
                label="AKA Last Name *"
                label-for="akalname"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="lname"
                  v-model="caregiverData.akalastname"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- AKA First Name -->
            <validation-provider
              #default="validationContext"
              name="AKA First Name"
            >
              <b-form-group
                label="AKA First Name *"
                label-for="akalname"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="lname"
                  v-model="caregiverData.akafirstname"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Spacer  -->
            <br>

            <!-- Date of Birth -->
            <validation-provider
              #default="validationContext"
              name="Date of Birth"
              rules="required"
            >
              <b-form-group
                label="Date of Birth"
                label-for="dateofbirth"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="caregiverData.dateofbirth"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d' }"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Place of Birth -->
            <validation-provider
              #default="validationContext"
              name="Place of Birth"
            >
              <b-form-group
                label="Place of Birth"
                label-for="Place of Birth"
              >
                <b-form-input
                  v-model="caregiverData.placeofbirth"
                  autofocus
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Spacer  -->
            <br>

            <!-- Sex/marital status -->
            <b-row>
              <!-- Sex -->
              <b-col
                cols="12"
                lg="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Sex"
                  rules="required"
                >
                  <b-form-group
                    label="* Sex"
                    label-for="sex"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="caregiverData.sex"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="sexOptions"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Marital Status -->
              <b-col
                cols="12"
                lg="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Marital Status"
                  rules="required"
                >
                  <b-form-group
                    label="* Marital Status"
                    label-for="marital-status"
                    :state="getValidationState(validationContext)"
                  >
                    <label
                      class="sr-only"
                      for="marital-status"
                    >
                      * Marital Status
                    </label>
                    <v-select
                      v-model="caregiverData.maritalstatus"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="maritalStatusOptions"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Spacer  -->
            <br>

            <!-- Weight/height  -->
            <b-row>
              <!-- Input Weight -->
              <b-col
                cols="12"
                lg="6"
              >
                <b-form-group
                  label="* Weight (lb)"
                  label-for="weight"
                >
                  <label
                    class="sr-only"
                    for="weight"
                  > * Weight (lb) </label>
                  <validation-provider
                    #default="validationContext"
                    name="Weight"
                    vid="weight"
                    rules="required"
                  >
                    <b-form-input
                      id="weight"
                      v-model="caregiverData.weight"
                      :state="getValidationState(validationContext)"
                      type="number"
                      step="0.1"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Input Height -->
              <b-col
                cols="12"
                lg="6"
              >
                <b-form-group
                  label="* Height (cm)"
                  label-for="weight"
                >
                  <label
                    class="sr-only"
                    for="weight"
                  > * Height (cm) </label>
                  <validation-provider
                    #default="validationContext"
                    name="Height"
                    vid="height"
                    rules="required"
                  >
                    <b-form-input
                      id="height"
                      v-model="caregiverData.height"
                      :state="getValidationState(validationContext)"
                      type="number"
                      step="0.1"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Eye/hair color  -->
            <b-row>
              <!-- Input Eye Color -->
              <b-col
                cols="12"
                lg="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Eye Color"
                  rules="required"
                >
                  <b-form-group
                    label="* Eye Color"
                    label-for="eye-color"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="caregiverData.eyecolor"
                      :state="getValidationState(validationContext)"
                      :dir="'ltr'"
                      :options="eyeColorOptions"
                      multiple
                      taggable
                      push-tags
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Input Hair Color -->
              <b-col
                cols="12"
                lg="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Hair Color"
                  rules="required"
                >
                  <b-form-group
                    label="* Hair Color"
                    label-for="hair-color"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="caregiverData.haircolor"
                      :state="getValidationState(validationContext)"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="hairColorOptions"
                      multiple
                      taggable
                      push-tags
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Spacer  -->
            <br>

            <!-- Address -->
            <b-form-group
              label="* Address"
              label-for="address"
            >
              <label
                class="sr-only"
                for="address"
              >
                * Address
              </label>
              <validation-provider
                #default="validationContext"
                name="Address"
                vid="address"
                rules="required"
              >
                <b-form-input
                  id="address"
                  v-model="caregiverData.address"
                  :state="getValidationState(validationContext)"
                  type="text"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Apt/Unit -->
            <b-form-group
              label="Apt/Unit"
              label-for="aptunit"
            >
              <label
                class="sr-only"
                for="aptunit"
              >
                Apt/Unit
              </label>
              <validation-provider
                #default="validationContext"
                name="Apt/Unit"
                vid="aptunit"
              >
                <b-form-input
                  id="aptunit"
                  v-model="caregiverData.aptunit"
                  :state="getValidationState(validationContext)"
                  type="text"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Street Number and/or Name -->
            <b-form-group
              label="* Street Number and/or Name"
              label-for="street"
            >
              <label
                class="sr-only"
                for="street"
              >
                * Street Number and/or Name
              </label>
              <validation-provider
                #default="validationContext"
                name="Street Number and/or Name"
                vid="street"
                rules="required"
              >
                <b-form-input
                  id="street"
                  v-model="caregiverData.street"
                  :state="getValidationState(validationContext)"
                  type="text"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Street or POB -->
            <b-form-group
              label="* Street or POB"
              label-for="street-pob"
            >
              <label
                class="sr-only"
                for="street-pob"
              > * Street or POB </label>
              <validation-provider
                #default="validationContext"
                name="Street or POB"
                vid="street-pob"
                rules="required"
              >
                <b-form-input
                  id="street-pob"
                  v-model="caregiverData.streetpob"
                  :state="getValidationState(validationContext)"
                  type="text"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- City -->
            <validation-provider
              #default="validationContext"
              name="city"
            >
              <b-form-group
                label="City or Town"
                label-for="city"
              >
                <b-form-input
                  id="city"
                  v-model="caregiverData.citytown"
                  autofocus
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-row>
              <b-col
                cols="12"
                lg="6"
              >
                <!-- State -->
                <validation-provider
                  #default="validationContext"
                  name="state"
                >
                  <b-form-group
                    label="State"
                    label-for="state"
                  >
                    <v-select
                      v-model="caregiverData.state"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="stateOptions"
                      taggable
                      push-tags
                      placeholder="Select or Type State"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                lg="6"
              >
                <!-- Postal/Zip Code -->
                <validation-provider
                  #default="validationContext"
                  name="postalCode"
                >
                  <b-form-group
                    label="Zip Code"
                    label-for="postalCode"
                  >
                    <b-form-input
                      id="postalCode"
                      v-model="caregiverData.zipcode"
                      autofocus
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Spacer  -->
            <br>

            <!-- Phone Number -->
            <validation-provider
              #default="validationContext"
              name="PhoneNumber"
            >
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Phone Number"
                    label-for="PhoneNumber"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        US (+1)
                      </b-input-group-prepend>
                      <b-form-input
                        id="PhoneNumber"
                        v-model="caregiverData.phonenumber"
                        v-mask="['(###) ###-####']"
                        type="tel"
                      />
                    </b-input-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-provider>

            <!-- Mobile Number -->
            <validation-provider
              #default="validationContext"
              name="mobileNumber"
            >
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Mobile Number"
                    label-for="mobileNumber"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        US (+1)
                      </b-input-group-prepend>
                      <b-form-input
                        id="mobileNumber"
                        v-model="caregiverData.mobilenumber"
                        v-mask="['###-###-####']"
                        type="tel"
                      />
                    </b-input-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-provider>

            <!-- Fax Number -->
            <validation-provider
              #default="validationContext"
              name="faxnumber"
            >
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Fax Number"
                    label-for="faxnumber"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        US (+1)
                      </b-input-group-prepend>
                      <b-form-input
                        id="faxnumber"
                        v-model="caregiverData.faxnumber"
                        v-mask="['###-###-####']"
                        type="tel"
                      />
                    </b-input-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-provider>

            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email Address"
              vid="email-address"
              rules="email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="caregiverData.emailaddress"
                  :state="getValidationState(validationContext)"
                  :type="email"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Spacer  -->
            <br>

            <!-- Emergency Contact Name -->
            <b-form-group
              label="* Emergency Contact Name"
              label-for="emergency-contact-name"
            >
              <label
                class="sr-only"
                for="emergency-contact-name"
              >
                * Emergency Contact Name
              </label>
              <validation-provider
                #default="validationContext"
                name="Emergency Contact Name"
                vid="emergency-contact-name"
                rules="required"
              >
                <b-form-input
                  id="emergency-contact-name"
                  v-model="caregiverData.emergencycontactname"
                  :state="getValidationState(validationContext)"
                  type="text"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Emergency Contact Email Address-->
            <validation-provider
              #default="validationContext"
              name="Email Address"
              vid="email-address"
              rules="email"
            >
              <b-form-group
                label="Emergency Contact Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="caregiverData.emergencycontactemailaddress"
                  :state="getValidationState(validationContext)"
                  :type="email"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Emergency Contact Number -->
            <b-form-group
              label="* Emergency Contact Number"
              label-for="emergency-contact-number"
            >
              <label
                class="sr-only"
                for="emergency-contact-number"
              >
                * Emergency Contact Number
              </label>
              <validation-provider
                #default="validationContext"
                name="Emergency Contact Number"
                vid="emergency-contact-number"
                rules="required"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    US (+1)
                  </b-input-group-prepend>
                  <b-form-input
                    id="emergency-contact-number"
                    v-model="caregiverData.emergencycontactnumber"
                    v-mask="['(###) ###-####']"
                    :state="getValidationState(validationContext)"
                    type="tel"
                  />
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Spacer  -->
            <br>

            <!-- SSN -->
            <validation-provider
              #default="validationContext"
              name="ssn"
            >
              <b-form-group
                label="SSN"
                label-for="ssn"
              >
                <b-form-input
                  id="ssn"
                  v-model="caregiverData.ssn"
                  v-mask="['###-##-####']"
                  autofocus
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- TIN -->
            <validation-provider
              #default="validationContext"
              name="tin"
            >
              <b-form-group
                label="TIN"
                label-for="tin"
              >
                <b-form-input
                  id="tin"
                  v-model="caregiverData.tin"
                  v-mask="['###-##-####']"
                  autofocus
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- CDL -->
            <b-form-group
              label="CDL"
              label-for="cdl"
            >
              <label
                class="sr-only"
                for="cdl"
              > CDL </label>
              <b-form-input
                id="cdl"
                v-model="caregiverData.cdl"
                type="text"
              />
            </b-form-group>

            <!-- Spacer  -->
            <br>

            <!-- ID Type  -->
            <validation-provider
              #default="validationContext"
              name="ID Type"
              rules="required"
            >
              <b-form-group
                label="* ID Type"
                label-for="id-type"
                :state="getValidationState(validationContext)"
              >
                <label class="sr-only"> * ID Type </label>
                <v-select
                  v-model="caregiverData.idtype"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="idTypeOptions"
                  taggable
                  push-tags
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- ID Number  -->
            <b-form-group
              label="* ID Number"
              label-for="id-number"
            >
              <label
                class="sr-only"
                for="id-number"
              > * ID Number </label>
              <validation-provider
                #default="validationContext"
                name="ID Number"
                vid="id-number"
                rules="required"
              >
                <b-form-input
                  id="id-number"
                  v-model="caregiverData.idnumber"
                  :state="getValidationState(validationContext)"
                  type="text"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- ID Expiration Date  -->
            <validation-provider
              #default="validationContext"
              name="ID Expiration Date"
              vid="id-expiration-date"
              rules="required"
            >
              <b-form-group
                label="* ID Expiration Date"
                label-for="id-expiration-date"
                :state="getValidationState(validationContext)"
              >
                <label
                  class="sr-only"
                  for="id-expiration-date"
                >
                  * ID Expiration Date
                </label>
                <flat-pickr
                  id="id-expiration-date"
                  v-model="caregiverData.idexpirationdate"
                  class="form-control"
                  :config="flatPickrConfig"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- ID State Issued  -->
            <b-form-group
              label="* ID State Issued"
              label-for="id-state-issued"
            >
              <label
                class="sr-only"
                for="id-state-issued"
              >
                * ID State Issued
              </label>
              <validation-provider
                #default="validationContext"
                name="ID State Issued"
                vid="id-state-issued"
                rules="required"
              >
                <b-form-input
                  id="id-state-issued"
                  v-model="caregiverData.idstateissued"
                  :state="getValidationState(validationContext)"
                  type="text"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- ID Document  -->
            <b-form-group
              label="* ID Document"
              label-for="id-document"
            >
              <label
                class="sr-only"
                for="id-document"
              > * ID Document </label>
              <validation-provider
                #default="validationContext"
                name="ID Document"
              >
                <b-form-file
                  v-model="idfile"
                  :state="getValidationState(validationContext)"
                  multiple
                  type="file"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Display Id documents -->
            <b-list-group
              v-if="idDocuments"
              flush
            >
              <b-list-group-item
                v-for="file in idDocuments"
                :key="file.key"
                class="d-flex justify-content-between text-truncate"
              >
                <span>
                  <b-link
                    :href="`${file.url}`"
                    target="_blank"
                    class="font-weight-bold pointer-events-auto"
                  >
                    {{ file.filename }}
                  </b-link>
                </span>
                <span class="d-inline-block">
                  <feather-icon
                    :id="`row-${file.key}-id-document-preview-icon`"
                    icon="EyeIcon"
                    size="16"
                    class="mr-1 pointer-events-auto"
                    role="button"
                    @click="$store.dispatch('caregiver/popupNotif', {text: file.filename,
                                                                     padding: '1em 3em',
                                                                     imageUrl: file.url,
                                                                     imageAlt: file.filename,
                                                                     showConfirmButton: false,
                                                                     showCloseButton: true,
                                                                     returnFocus: false,})"
                  />
                  <b-tooltip
                    title="Preview"
                    :target="`row-${file.key}-id-document-preview-icon`"
                  />

                  <feather-icon
                    :id="`row-${file.key}-id-document-delete-icon`"
                    icon="TrashIcon"
                    size="16"
                    role="button"
                    @click="deleteFile({file:file, column:'iddocument'})"
                  />
                  <b-tooltip
                    title="Delete"
                    :target="`row-${file.key}-id-document-delete-icon`"
                  />
                </span>
              </b-list-group-item>
            </b-list-group>

            <!-- Spacer  -->
            <br>

            <!-- Misc. Number  -->
            <b-form-group
              label="Misc. Number"
              label-for="misc-number"
            >
              <label
                class="sr-only"
                for="misc-number"
              > Misc. Number </label>
              <b-form-input
                id="misc-number"
                v-model="caregiverData.miscnumber"
                type="text"
              />
            </b-form-group>

            <!-- Misc. Number Bil  -->
            <b-form-group
              label="Misc. Number Bil"
              label-for="misc-number-bil"
            >
              <label
                class="sr-only"
                for="misc-number-bil"
              >
                Misc. Number Bil
              </label>
              <b-form-input
                id="misc-number-bil"
                v-model="caregiverData.miscnumberbil"
                type="text"
              />
            </b-form-group>

            <!-- Spacer  -->
            <br>

            <!-- Position  -->
            <b-form-group
              label="* Position"
              label-for="position"
            >
              <label
                class="sr-only"
                for="position"
              > * Position </label>
              <validation-provider
                #default="validationContext"
                name="Position"
                vid="position"
                rules="required"
              >
                <b-form-input
                  id="position"
                  v-model="caregiverData.position"
                  :state="getValidationState(validationContext)"
                  type="text"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Hire Date -->
            <validation-provider
              #default="validationContext"
              name="Hire Date"
              rules="required"
            >
              <b-form-group
                label="Hire Date"
                label-for="hireDate"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="caregiverData.hiredate"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d' }"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- First Day Date  -->
            <validation-provider
              #default="validationContext"
              name="First Day Date"
              vid="first-day-date"
              rules="required"
            >
              <b-form-group
                label="* First Day Date"
                label-for="first-day-date"
                :state="getValidationState(validationContext)"
              >
                <label
                  class="sr-only"
                  for="first-day-date"
                >
                  * First Day Date
                </label>
                <flat-pickr
                  id="first-day-date"
                  v-model="caregiverData.firstdaydate"
                  class="form-control"
                  :config="flatPickrConfig"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Signature  -->
            <!-- <b-form-group
              label="* Signature"
              label-for="signature"
            >
              <label
                class="sr-only"
                for="signature"
              > * Signature </label>
              <validation-provider
                #default="validationContext"
                name="Signature"
              >
                <b-form-file
                  v-model="signatureFile"
                  :state="getValidationState(validationContext)"
                  multiple
                  type="file"
                  capture
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group> -->

            <!-- Spacer  -->
            <!-- <br> -->

            <!-- Employment Status -->
            <!-- <validation-provider
              #default="validationContext"
              name="Employment Status"
              rules="required"
            >
              <b-form-group
                label="* Employment Status"
                label-for="id-type"
                :state="getValidationState(validationContext)"
              >
                <label class="sr-only"> * Employment Status </label>
                <v-select
                  v-model="caregiverData.employmentstatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  taggable
                  push-tags
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->

            <!-- Employment Status Date  -->
            <validation-provider
              #default="validationContext"
              name="Employment Status Date"
              vid="employment-status-date"
              rules="required"
            >
              <b-form-group
                label="* Employment Status Date"
                label-for="employment-status-date"
                :state="getValidationState(validationContext)"
              >
                <label
                  class="sr-only"
                  for="employment-status-date"
                >
                  Employment Status Date
                </label>
                <flat-pickr
                  id="employment-status-date"
                  v-model="caregiverData.employmentstatusdate"
                  class="form-control"
                  :config="flatPickrConfig"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Spacer  -->
            <!-- <br> -->

            <!--  Profile Picture  -->
            <!-- <b-form-group
              label="*  Profile Picture"
              label-for=" profile-picture"
            >
              <label
                class="sr-only"
                for=" profile-picture"
              > *  Profile Picture </label>
              <validation-provider
                #default="validationContext"
                name=" profile-picture"
              >
                <b-form-file
                  v-model="profilepictureFile"
                  :state="getValidationState(validationContext)"
                  accept="image/*"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group> -->

            <!-- Display profile pic -->
            <!-- <b-list-group flush>
              <b-list-group-item
                v-for="file in profilePics"
                :key="file.key"
              >
                <b-row>
                  <b-col cols="9">
                    {{ file.filename }}
                  </b-col>
                  <b-col cols="3">
                    <feather-icon
                      size="17"
                      icon="TrashIcon"
                      class="cursor-pointer float-right"
                      @click="deleteFile({file:file, column:'iddocument'})"
                    />
                    <feather-icon
                      v-if="file.fromDb"
                      size="17"
                      icon="EyeIcon"
                      class="cursor-pointer float-right mr-1"
                      @click="viewDocu(file.url)"
                    />
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group> -->

            <!-- Spacer  -->
            <br>

            <!-- COVID-19 Vaccine  -->
            <b-form-group
              label="COVID-19 Vaccine"
              label-for="position"
            >
              <label
                class="sr-only"
                for="position"
              > COVID-19 Vaccine </label>
              <validation-provider
                #default="validationContext"
                name="Position"
              >
                <b-form-input
                  v-model="caregiverData.covid19vaccine"
                  :state="getValidationState(validationContext)"
                  type="text"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- COVID-19 Vaccine Card  -->
            <b-form-group
              label="COVID-19 Vaccine Card"
              label-for="covid19-vaccine-card"
            >
              <label
                class="sr-only"
                for="covid19-vaccine-card"
              > COVID-19 Vaccine Card </label>
              <validation-provider
                #default="validationContext"
                name="covid19-vaccine-card"
              >
                <b-form-file
                  v-model="covid19vaccinecardFile"
                  :state="getValidationState(validationContext)"
                  accept="image/*"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Display vax card -->
            <b-list-group
              v-if="vaxCards"
              flush
            >
              <b-list-group-item
                v-for="file in vaxCards"
                :key="file.key"
                class="d-flex justify-content-between text-truncate"
              >
                <span>
                  <b-link
                    :href="`${file.url}`"
                    target="_blank"
                    class="font-weight-bold pointer-events-auto"
                  >
                    {{ file.filename }}
                  </b-link>
                </span>
                <span class="d-inline-block">
                  <feather-icon
                    :id="`row-${file.key}-id-document-preview-icon`"
                    icon="EyeIcon"
                    size="16"
                    class="mr-1 pointer-events-auto"
                    role="button"
                    @click="$store.dispatch('caregiver/popupNotif', {text: file.filename,
                                                                     padding: '1em 3em',
                                                                     imageUrl: file.url,
                                                                     imageAlt: file.filename,
                                                                     showConfirmButton: false,
                                                                     showCloseButton: true,
                                                                     returnFocus: false,})"
                  />
                  <b-tooltip
                    title="Preview"
                    :target="`row-${file.key}-id-document-preview-icon`"
                  />

                  <feather-icon
                    :id="`row-${file.key}-id-document-delete-icon`"
                    icon="TrashIcon"
                    size="16"
                    role="button"
                    @click="deleteFile({file:file, column:'covid19vaccinecard'})"
                  />
                  <b-tooltip
                    title="Delete"
                    :target="`row-${file.key}-id-document-delete-icon`"
                  />
                </span>
              </b-list-group-item>
            </b-list-group>

            <!-- <b-list-group flush>
              <b-list-group-item
                v-for="file in vaxCards"
                :key="file.key"
              >
                <b-row>
                  <b-col cols="9">
                    {{ file.filename }}
                  </b-col>
                  <b-col cols="3">
                    <feather-icon
                      size="17"
                      icon="TrashIcon"
                      class="cursor-pointer float-right"
                      @click="deleteFile({file:file, column:'covid19vaccinecard'})"
                    />
                    <feather-icon
                      v-if="file.fromDb"
                      size="17"
                      icon="EyeIcon"
                      class="cursor-pointer float-right mr-1"
                      @click="viewDocu(file.url)"
                    />
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group> -->

            <!-- Spacer  -->
            <br>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="mr-2"
                type="submit"
                :disabled="noChanges"
              >
                {{ caregiverData.id ? "Update" : "Add " }}
              </b-button>
              <b-button
                v-if="!caregiverData.id"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-dark"
                :disabled="btnDisabled"
                @click="$store.commit('caregiver/SET_caregiver', {})"
              >
                Reset
              </b-button>
              <b-button
                v-if="caregiverData.id"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-dark"
                :disabled="btnDisabled"
                @click="$store.commit('caregiver/SET_isCaregiverHandlerSidebarActive', false)"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BFormFile,
  BListGroup,
  BListGroupItem,
  BLink,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  toRefs,
  // computed,
  // ref,
  // watchEffect,
  inject,
} from '@vue/composition-api'
import { mask } from 'vue-the-mask'
// import store from '@/store'
import useCaregiverHandler from './useCaregiverHandler'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BFormFile,
    ValidationObserver,
    BListGroup,
    BListGroupItem,
    BLink,
    BTooltip,
  },
  directives: {
    Ripple,
    mask,
  },
  model: {
    prop: 'isCaregiverHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isCaregiverHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation()

    const refreshTableTrigger = inject('refreshTable', null)

    const {
      // variables
      initialCaregiverData,
      caregiverData,
      idfile,
      idImageToDisplay,
      idDocuments,
      profilePics,
      vaxCards,
      signatureFile,
      profilepictureFile,
      covid19vaccinecardFile,
      btnDisabled,
      noChanges,

      // methods
      viewDocu,
      deleteFile,
      onSubmit,

      // Options
      sexOptions,
      maritalStatusOptions,
      eyeColorOptions,
      hairColorOptions,
      idTypeOptions,
      statusOptions,
      stateOptions,

      // others
      flatPickrConfig,
      callParentMethod,
    } = useCaregiverHandler(toRefs(props), clearForm, emit, refreshTableTrigger)
    /* watchEffect(store.state.caregiver.caregiver, () => {
      if (Object.keys(initialCaregiverData.value).length === 0 && Object.keys(store.state.caregiver.caregiver).length > 0) {
        initialCaregiverData.value = JSON.parse(JSON.stringify(store.state.caregiver.caregiver))
      }
    }) */
    // initialCaregiverData.value = ref(JSON.parse(JSON.stringify(store.state.caregiver.caregiver)))
    // console.log('phew', initialCaregiverData.value, caregiverData.value, JSON.stringify(initialCaregiverData.value) === JSON.stringify(caregiverData.value))

    // const noChanges = ref(computed(() => JSON.stringify(initialCaregiverData) === JSON.stringify(caregiverData)))
    return {
      // variables
      initialCaregiverData,
      caregiverData,
      idfile,
      idImageToDisplay,
      idDocuments,
      profilePics,
      vaxCards,
      signatureFile,
      profilepictureFile,
      covid19vaccinecardFile,
      btnDisabled,
      noChanges,
      // methods
      refFormObserver,
      onSubmit,
      resetForm,
      getValidationState,
      viewDocu,
      deleteFile,

      // options
      sexOptions,
      maritalStatusOptions,
      eyeColorOptions,
      hairColorOptions,
      idTypeOptions,
      statusOptions,
      stateOptions,

      // others
      flatPickrConfig,
      callParentMethod,
      refreshTableTrigger,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.col-2 {
  margin-top: 30px;
}
</style>
