import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import CaregiverStoreModule from './CaregiverStoreModule'
import CaregiverShiftStoreModule from './CaregiverShiftStoreModule'

export default function caregiverStoreInit() {
  const CAREGIVER_APP_STORE_MODULE_NAME = 'caregiver'
  const CAREGIVERSHIFT_APP_STORE_MODULE_NAME = 'caregiverShift'

  // Register module caregiver
  if (!store.hasModule(CAREGIVER_APP_STORE_MODULE_NAME)) {
    store.registerModule(
      CAREGIVER_APP_STORE_MODULE_NAME,
      CaregiverStoreModule,
    )
  }

  // Register module caregiverShift
  if (!store.hasModule(CAREGIVERSHIFT_APP_STORE_MODULE_NAME)) {
    store.registerModule(
      CAREGIVERSHIFT_APP_STORE_MODULE_NAME,
      CaregiverShiftStoreModule,
    )
  }

  // UnRegister on leave caregiver
  onUnmounted(() => {
    if (store.hasModule(CAREGIVER_APP_STORE_MODULE_NAME)) {
      store.unregisterModule(CAREGIVER_APP_STORE_MODULE_NAME)
    }
  })

  // UnRegister on leave caregiverShift
  onUnmounted(() => {
    if (store.hasModule(CAREGIVERSHIFT_APP_STORE_MODULE_NAME)) {
      store.unregisterModule(CAREGIVERSHIFT_APP_STORE_MODULE_NAME)
    }
  })
}
