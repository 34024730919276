/* eslint-disable no-undef */
/* eslint-disable radix */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import Vue from 'vue'
import axios from '@axios'
import { reactive } from '@vue/composition-api'
import Swal from 'sweetalert2'
import router from '@/router'
import moment from 'moment'
import SockJS from 'sockjs-client'

const sock = new SockJS('https://socket.motivit.com:443/echo')

export default {
  namespaced: true,
  state: reactive({
    notificationSockId: 'coc-n01',
    isLoadingCalendar: true,
    shifts: [],
    tasks: [],
    shiftRaw: [],
    tasksRaw: [],
    isCalendarOverlaySidebarActive: false,
    isEventHandlerSidebarActive: false,
    isShift: false,
    isTasks: false,
    isMail: false,
    blankEvent: {
      title: '',
      start: '',
      end: '',
      start_time: '',
      end_time: '',
      allDay: false,
      extendedProps: {
        status: '1',
        recurrence: false,
        isRecurr: false,
        recurrence_pattern: 'daily',
        dailyPattern: {
          pattern: 'every',
          day: '1',
        },
        weeklyPattern: {
          every_week: '1',
          days: [],
        },
        monthlyPattern: {
          pattern: 'pattern1',
          input_one: '',
          input_two: '',
          selected1: [],
          selected2: [],
          input_three: '',
        },
        yearlyPattern: {
          recur_every: '1',
          pattern: 'pattern1',
          input_one: '1',
          input_two: '2',
          selected1: [],
          selected2: [],
          selected3: [],
          selected4: [],
        },
        pattern_option: {},
        repeat_every: null,
        repeat_on: [],
        repeat_until: null,
        client: null,
        caregiver: '',
        confirmation: null,
        clientRate: null,
        authorization: '',
        caregiverRate: null,
        client_fullname: null,
        mileage: '',
        location: '',
        tags: [],
        reason: '',
        caregiverTask: [],
        shift_tasks: [],
        // For send mail data
        orig_start: '',
        orig_end: '',
        delivery: null,
        userEmail: '',
        caregiverEmail: '',
        relateTo: '',
        clientEmail: '',
        subject: '',
        message: '',
        signature: '',
        files: null,
      },
    },
    event: {
      title: '',
      start: '',
      end: '',
      allDay: false,
      extendedProps: {
        status: null,
        recurrence: false,
        recurrence_pattern: 'daily',
        dailyPattern: {
          pattern: 'every',
          day: '1',
        },
        weeklyPattern: {
          every_week: '1',
          days: [],
        },
        monthlyPattern: {
          pattern: 'pattern1',
          input_one: '',
          input_two: '',
          selected1: [],
          selected2: [],
          input_three: '',
        },
        yearlyPattern: {
          recur_every: '1',
          pattern: 'pattern1',
          input_one: '1',
          input_two: '2',
          selected1: [],
          selected2: [],
          selected3: [],
          selected4: [],
        },
        pattern_option: {},
        repeat_every: null,
        repeat_on: [],
        repeat_until: null,
        client: '',
        caregiver: '',
        confirmation: null,
        clientRate: null,
        authorization: '',
        caregiverRate: null,
        mileage: '',
        location: '',
        tags: [],
        reason: '',
        caregiverTask: [],
        shift_tasks: [],
        // For send mail data
        delivery: null,
        userEmail: '',
        caregiverEmail: '',
        relateTo: '',
        clientEmail: '',
        subject: '',
        message: '',
        signature: '',
        files: null,
      },
    },
    calendarOptions: [
      {
        id: null,
        class: 'show-all',
        label: 'Show All',
      },
      {
        id: '1',
        class: 'scheduled',
        label: 'Scheduled',
      },
      {
        id: '2',
        class: 'in-progress',
        label: 'In Progress',
      },
      {
        id: '3',
        class: 'completed',
        label: 'Completed',
      },
      {
        id: '4',
        class: 'm-clock-in',
        label: 'Missed Clock-in',
      },
      {
        id: '5',
        class: 'm-clock-out',
        label: 'Missed Clock-out',
      },
      {
        id: '6',
        class: 'open-shift',
        label: 'Open Shift',
      },
      {
        id: '7',
        class: 'c-client',
        label: 'Cancelled by Client',
      },
      {
        id: '8',
        class: 'c-caregiver',
        label: 'Cancelled by Caregiver',
      },
      // {
      //   id: '9',
      //   class: 'tentative-not-echeduled',
      //   label: 'Tentative/Not Scheduled',
      // },
      {
        id: '10',
        class: 'pending-confirmation',
        label: 'Pending Confirmation',
      },
      // {
      //   id: '11',
      //   class: 'attention-required',
      //   label: 'Attention Required',
      // },
    ],
    uData: JSON.parse(localStorage.getItem('userData')),

    /* For Calendar */
    clientOptions: [],
    caregiverOptions: [],
    Authorization: [],
    tagOptions: [
      { label: '1st Call/Email', value: '1st-call-email' },
      { label: 'Accounting', value: 'accounting' },
      { label: 'Admin Communication', value: 'admin-communication' },
      { label: 'Background Check', value: 'background-check' },
      { label: 'Caregiver Call Off', value: 'caregiver-call-off' },
      { label: 'Caregiver Deactivation', value: 'caregiver-deactivation' },
    ],
    recurrencePattern: {
      weeklyPatternDays: [
        { text: 'Monday', value: 'monday' },
        { text: 'Tuesday', value: 'tuesday' },
        { text: 'Wednesday', value: 'wednesday' },
        { text: 'Thursday', value: 'thursday' },
        { text: 'Friday', value: 'friday' },
        { text: 'Saturday', value: 'saturday' },
        { text: 'Sunday', value: 'sunday' },
      ],
      monthlyPattern: {
        option1: [
          { label: 'first', value: 'first' },
          { label: 'second', value: 'second' },
          { label: 'third', value: 'third' },
          { label: 'fourth', value: 'fourth' },
          { label: 'last', value: 'last' },
        ],
        option2: [
          { label: 'Monday', value: 'monday' },
          { label: 'Tuesday', value: 'tuesday' },
          { label: 'Wednesday', value: 'wednesday' },
          { label: 'Thursday', value: 'thursday' },
          { label: 'Friday', value: 'friday' },
          { label: 'Saturday', value: 'saturday' },
          { label: 'Sunday', value: 'sunday' },
        ],
      },
      yearlyPattern: {
        option1: [
          { label: 'January', value: '01' },
          { label: 'February', value: '02' },
          { label: 'March', value: '03' },
          { label: 'April', value: '04' },
          { label: 'May', value: '05' },
          { label: 'June', value: '06' },
          { label: 'July', value: '07' },
          { label: 'August', value: '08' },
          { label: 'September', value: '09' },
          { label: 'October', value: '10' },
          { label: 'November', value: '11' },
          { label: 'December', value: '12' },
        ],
        option2: [
          { label: 'first', value: 'first' },
          { label: 'second', value: 'second' },
          { label: 'third', value: 'third' },
          { label: 'fourth', value: 'fourth' },
          { label: 'last', value: 'last' },
        ],
        option3: [
          { label: 'Monday', value: 'monday' },
          { label: 'Tuesday', value: 'tuesday' },
          { label: 'Wednesday', value: 'wednesday' },
          { label: 'Thursday', value: 'thursday' },
          { label: 'Friday', value: 'friday' },
          { label: 'Saturday', value: 'saturday' },
          { label: 'Sunday', value: 'sunday' },
        ],
        option4: [
          { label: 'January', value: 'january' },
          { label: 'February', value: 'february' },
          { label: 'March', value: 'march' },
          { label: 'April', value: 'april' },
          { label: 'May', value: 'may' },
          { label: 'June', value: 'june' },
          { label: 'July', value: 'july' },
          { label: 'August', value: 'august' },
          { label: 'September', value: 'september' },
          { label: 'October', value: 'october' },
          { label: 'November', value: 'november' },
          { label: 'December', value: 'december' },
        ],
      },
    },
    statusFilter: [1, 2, 3, 6, 7, 8, 10],
  }),
  mutations: {
    setShifts: (state, payload) => state.shifts = payload,
    setTasks: (state, payload) => state.tasks = payload,
    setShiftsRaw: (state, payload) => state.shiftRaw = payload,
    setTasksRaw: (state, payload) => state.tasksRaw = payload,
    setIsCalendarOverlaySidebarActive: (state, payload) => state.isCalendarOverlaySidebarActive = payload,
    setIsEventHandlerSidebarActive: (state, payload) => {
      state.isEventHandlerSidebarActive = payload
      if (payload === false) {
        state.event = state.blankEvent
        state.isTasks = false
        state.isTasks = false
        state.isMail = false
      }
    },
    setEvent: (state, payload) => state.event = payload,
    appendTasks: (state, payload) => {
      payload.forEach(task => state.tasks.push(task))
    },
    openMail: state => {
      state.isMail = true
      state.isShift = false
      state.isTasks = false
    },
    openShift: state => {
      state.isShift = true
      state.isTasks = false
      state.isMail = false
    },
    openTask: state => {
      state.isTasks = true
      state.isShift = false
      state.isMail = false
    },
    setCalendarOptions: (state, payload) => {
      // state.event = payload.blankEvent
      state.clientOptions = payload.client
      state.caregiverOptions = payload.caregiver
      state.Authorization = payload.administration
    },
    SET_LOADING_CALENDAR: (state, payload) => state.isLoadingCalendar = payload,
  },
  getters: {},
  actions: {
    getCalendarOptions: state => {
      axios
        .post(`${Vue.prototype.$apiUrl2}calendar/getSelect`, {
          param: {},
        },
        {
          headers: {
            'Content-type': 'application/json',
          },
        })
        .then(response => {
          state.commit('setCalendarOptions', response.data.response.data)
        })
        .catch(error => {
          // console.log(error)
        })
    },

    formatShifts: (state, payload) => {
      // console.log('payload', payload)
      const tempShifts = []
      payload.forEach(shift => {
      //   const { tasks } = shift
      //   if (tasks) {
      //     // eslint-disable-next-line no-unused-expressions
      //     tasks.forEach(task => {
      //       // console.log('task',task)
        // const title = task.description ? task.description.replace(/<\/?[^>]+(>|$)/g, '') : task.task
        //       task.status = task.status === '' ? 'inprogress' : task.status
        tempShifts
          .push({
            id: shift.id,
            title: `${shift.client_fullname}`,
            // groupId: shift.shift_id,
            start: shift.start_date ? shift.start_date : shift.start,
            end: shift.end_date ? shift.end_date : shift.end,
            extendedProps: {
              calendar: shift.status ? shift.status : 'future',
            },
          })
      //     })
      //   }
      })
      // console.log('tempShifts', tempShifts)

      // state.commit('setTasks', tempShifts) /* set tasks */
      state.commit('setShifts', tempShifts)/* set shifts */
      // state.shifts = tempShifts
      // state.commit('setTasksRaw', tempTasks) /* set raw task for filter use */
      // state.dispatch('fetchRecurrence', payload) /* fetch all recurence */
    },

    submit: (state, payload) => {
      // console.log('payload', payload)
      const uData = localStorage.getItem('userData')
      return new Promise((resolve, reject) => {
        if (payload.actions !== 'testCalendar') {
          axios
            .post(`${Vue.prototype.$apiUrl2}calendar/${payload.action}`, {
              param: {
                shiftEvent: payload.data,
                userData: JSON.parse(uData),
              },
            },
            {
              headers: {
                'Content-type': 'application/json',
              },
            })
            .then(response => resolve(response))
            .catch(error => reject(error))
        }
      })
    },

    refetchCalendarEvents: state => {
      const formData = new FormData()
      const data = JSON.stringify({
        action: 'getById1',
        id: router.currentRoute.params.caregiverId,
      })
      formData.append('data', data)

      axios
        .post(`${Vue.prototype.$apiUrl2}caregiver/action`, formData, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        })
        .then(response => {
          // console.log('response', response)
          // console.log('events', events)
          response.data.response.data.shifts.forEach((f, i) => {
            // eslint-disable-next-line no-param-reassign
            response.data.response.data.shifts[i].title = `${f.client_fullname} => ${f.id}`
            // eslint-disable-next-line no-param-reassign
            response.data.response.data.shifts[i].caregiver = router.currentRoute.params.caregiverId
          })
          // console.log('response.data.response.data.shift', response.data.response.data.shifts)
          // events.forEach(f => console.log(f.id, f.extendedProps))
          state.commit('setShifts', response.data.response.data.shifts)
          // store.dispatch('caregiverShift/formatShifts', )
        })
        .catch(error => {
          // console.log(error)
        })
    },

    popupNotif: (state, payload) => {
      Swal.fire(payload)
    },

    initializeSockJs: (state, payload) => {
      sock.onopen = () => {
        // console.log('Connection established.')
      }

      sock.onmessage = event => {
        const data = JSON.parse(event.data)
        if (data.id === payload.id) {
          state.dispatch('getSockMsg', data.msg)
        }
      }

      sock.onclose = () => {
      // console.warn('Connection closed.')
        SockJS.call(sock, sock.url)
      }
    },

    getSockMsg: (state, sockMsg) => {
      if (sockMsg) {
      // Trigger reload or something here
        // console.log(sockMsg)
        // state.dispatch('getShifts')
        state.dispatch('refetchCalendarEvents')
      }
    },

    sendSockMsg: (state, payload) => {
      sock.send(JSON.stringify({ id: payload.id, msg: payload.sockMsg }))
    },
  },
}
