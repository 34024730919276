<template>
  <!-- <div class="d-flex">
    <span class="pageTitle">
      <h1>{{ $store.state.caregiver.pageTitle }} <span class="text-black-50"> |</span></h1>
    </span> -->
  <div class="row breadcrumbs-top">
    <div class="col-12">
      <h2
        class="content-header-title float-left pr-1 mb-0"
      >
        {{ $store.state.caregiver.pageTitle }}
      </h2>
      <b-breadcrumb class="my-auto">
        <b-breadcrumb-item>
          <b-link
            :to="{path:'/caregiver'}"
            class="router-link-active"
            target="_self"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16px"
              height="16px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="align-text-top feather feather-home"
            >
              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
              <polyline points="9 22 9 12 15 12 15 22" />
            </svg>
          </b-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item :active="!isCaregiverPage">
          <b-link
            v-if="isCaregiverPage"
            :to="{ name: 'caregiver' }"
            target="_self"
            class="text-primary"
          > Caregivers </b-link>
          <span
            v-else
            class="text-primary"
          >
            Caregivers
          </span>
        </b-breadcrumb-item>
        <b-breadcrumb-item
          v-if="isCaregiverPage"
          active
        >
          <span>{{ `${$store.state.caregiver.caregiver.firstname} ${$store.state.caregiver.caregiver.lastname}` }}</span>
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
  </div>
</template>
<script>
import { BLink, BBreadcrumb, BBreadcrumbItem } from 'bootstrap-vue'
// import store from '@/store'
import router from '@/router'

export default {
  components: {
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,
  },
  setup() {
    const isCaregiverPage = router.currentRoute.params.caregiverId ? true : null
    // console.log('isCaregiverPage', isCaregiverPage)
    // console.log('isCaregiverPage', store.state.caregiver.caregiver)
    return {
      isCaregiverPage,
    }
  },
}
</script>

<style lang="scss" scoped>
.pageTitle{
  display: inline-block;
}
.breadcrumb-wrapper{
  display: inline-block;
  margin-top: 4px;
}
</style>
