<template>
  <div>
    <caregiver-nav class="mb-2" />
    <div class="no-gutters">
      <!-- Default page -->
      <b-card no-body>
        <div class="m-1">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <h3 class="pr-1">
                {{ $store.state.caregiver.pageTitle }}
              </h3>
              <!-- <b-button
                  id="addCaregiverBtn"
                  variant="primary"
                  @click.prevent="$store.dispatch('caregiver/resetInputValidation'), $store.commit('caregiver/SET_isCaregiverHandlerSidebarActive', true)"
                >
                  <feather-icon
                    icon="UserPlusIcon"
                    size="16"
                  />
                </b-button> -->
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <!-- sorting  -->
                <v-select
                  id="sortBySelect"
                  v-model="accountStatus"
                  item-text="label"
                  item-value="value"
                  :options="statusOptions"
                  class="mr-1 employment-status"
                  :clearable="false"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
                <!-- filter -->
                <b-input-group
                  class="flex-grow-1 input-group-merge"
                >
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Find Caregiver"
                    debounce="1500"
                    @input="$store.commit('caregiver/SET_tableBusy', true)"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="SearchIcon"
                      class="text-muted"
                    />
                  </b-input-group-append>
                  <!-- <b-input-group-append>
                            <b-button
                              :disabled="!filter"
                              @click="filter = ''"
                            >
                              Clear
                            </b-button>
                          </b-input-group-append> -->
                </b-input-group>
              </div>
            </b-col>
          </b-row>
        </div>

        <caregiver-table ref="caregiverTable" />
        <!-- <caregiver-table @edit-handler="editHandler" /> -->
        <b-row class="px-1 mb-1">
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
              class="text-muted"
            >Showing
              <span v-if="$store.state.caregiver.totalRows > 0">{{ ($store.state.caregiver.currentPage - 1) * $store.state.caregiver.perPage + 1 }}</span>
              <span v-if="$store.state.caregiver.totalRows === 0">0</span>
              to
              <span v-if="$store.state.caregiver.currentPage < $store.state.caregiver.totalRows / $store.state.caregiver.perPage">{{ $store.state.caregiver.currentPage * $store.state.caregiver.perPage }}</span>
              <span v-if="$store.state.caregiver.currentPage > $store.state.caregiver.totalRows / $store.state.caregiver.perPage">{{ $store.state.caregiver.totalRows }}</span>
              of {{ $store.state.caregiver.totalRows }} entries</span>
          </b-col>
          <!-- pagination -->
          <b-col
            cols="12"
            md="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="$store.state.caregiver.totalRows"
              :per-page="$store.state.caregiver.perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 mt-1 mt-sm-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-card>
      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
      />
      <caregiver-handler
        v-model="$store.state.caregiver.isCaregiverHandlerSidebarActive"
        @toast-notif="toastNotif"
      />
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { computed, ref, provide } from '@vue/composition-api'
import vSelect from 'vue-select'

import {
  BCard,
  BPagination,
  BInputGroup,
  BFormInput,
  BRow,
  BCol,
  BInputGroupAppend,
  // BButton,
} from 'bootstrap-vue'
import CaregiverNav from './CaregiverNav.vue'
import CaregiverTable from './CaregiverTable.vue'
import useCaregiver from './useCaregiver'
import CaregiverHandler from './CaregiverHandler.vue'
import caregiverStoreInit from './caregiverStoreInit'

export default {
  directives: {
    Ripple,
  },
  components: {
    CaregiverNav,
    CaregiverHandler,
    CaregiverTable,
    // default
    BCard,
    BPagination,
    BInputGroup,
    BFormInput,
    vSelect,
    BRow,
    BCol,
    BInputGroupAppend,
    // BButton,
  },
  setup() {
    const caregiverTable = ref(null)
    caregiverStoreInit()

    const {
      caregiver,
      addCaregiver,
      updateCaregiver,
      clearCaregiverData,

      fields,
      items,
      accountStatus,
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      editHandler,
      rawItems,
      statusOptions,
      toastNotif,
    } = useCaregiver()

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    const formatHiredDate = date => moment(date, 'YYYY-MM-DD').format('LL')

    const sortOptions = computed(() => fields.value.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key })))

    const refreshTable = () => caregiverTable.value.refreshTable()
    provide('refreshTable', refreshTable)
    return {
      caregiver,
      addCaregiver,
      updateCaregiver,
      clearCaregiverData,

      // default
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn: [],
      fields,
      /* eslint-disable global-require */
      items,
      rawItems,
      statusOptions,
      accountStatus,

      // methods
      onFiltered,
      formatHiredDate,
      sortOptions,
      editHandler,
      toastNotif,
      caregiverTable,
      refreshTable,
    }
  },
  computed: {},
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.pageTitle{
  display: inline-block;
}
.breadcrumb-wrapper{
  display: inline-block;
  margin-top: 4px;
}

.add-btn {
  /* width: 36px; */
  height: 36px;
  background: transparent linear-gradient(90deg, #b7cf3e 0%, #569099 100%) 0% 0%
  no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}

#addCaregiverBtn {
    background: transparent linear-gradient(90deg, #B7CF3E 0%, #569099 100%) 0% 0% no-repeat padding-box!important;
}

.employment-status {
  /* width: 100%; */
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

/* [dir] h1, h2, h3, h4, h5{
  color: #33475B !important;
} */

[dir] .breadcrumb {
  font-size: 14px;
  line-height: 18px;
}
/* [dir] .breadcrumb-item.active{
  color: #33475B !important;
} */
</style>
