/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable no-return-assign */
import Vue from 'vue'
import axios from '@axios'
import { reactive } from '@vue/composition-api'
import Swal from 'sweetalert2'
import router from '@/router'

export default {
  namespaced: true,
  state: reactive({
    pageTitle: 'Caregivers',
    navLevel: 1,
    currentPage: 1,
    totalRows: 10,
    perPage: 10,
    filter: '',
    pageOptions: [],
    sortBy: '',
    sortDirection: 'asc',
    sortDesc: false,
    items: [],
    rawItems: [],
    fields: [
      // { key: 'id', label: 'id', sortable: true },
      { key: 'lastname', label: 'Caregivers', sortable: true },
      // { key: 'rating', label: 'rating', sortable: true },
      { key: 'emailaddress', label: 'Email Address' },
      { key: 'contact_number', label: 'Contact #' },
      { key: 'location', label: 'Shift Location' },
      // { key: 'address', label: 'Address' },
      { key: 'employmentstatus', label: 'status' },
      'actions',
      // 'position',
      // { key: 'sex', label: 'gender' },
      // { key: 'citytown', label: 'City' },
    ],
    isCaregiverHandlerSidebarActive: false,
    isCalendarOverlaySidebarActive: false,
    caregiver: {},
    employmentstatus: '',
    tableBusy: true,
  }),
  mutations: {
    SET_ITEMS: (state, payload) => state.items = payload,
    SET_ITEM: (state, payload) => {
      state.items[payload.id] = payload.data
    },
    SET_RAW_ITEMS: (state, payload) => state.rawItems = payload,
    SET_FIELDS: (state, payload) => state.fields = payload,
    SET_ROWS: (state, payload) => state.totalRows = payload,
    SET_FILTERS: (state, payload) => state.filter = payload,
    SET_PERPAGE: (state, payload) => state.perPage = payload,
    SET_CURR_PAGE: (state, payload) => state.currentPage = payload,
    SET_SORTBY: (state, payload) => state.sortBy = payload,
    SET_SORTDIRECT: (state, payload) => state.sortDirection = payload,
    SET_SORTDESC: (state, payload) => state.sortDesc = payload,
    SET_isCaregiverHandlerSidebarActive: (state, payload) => state.isCaregiverHandlerSidebarActive = payload,
    SET_isCalendarOverlaySidebarActive: (state, payload) => state.isCalendarOverlaySidebarActive = payload,
    SET_caregiver: (state, payload) => state.caregiver = payload,
    SET_employmentstatus: (state, payload) => state.employmentstatus = payload,
    SET_tableBusy: (state, payload) => state.tableBusy = payload,
    SET_navLevel: (state, payload) => state.navLevel = payload,
  },
  getters: {},
  actions: {
    setCaregiverStatus: async (ctx, payload) => {
      const formData = new FormData()

      const data = JSON.stringify({
        action: 'setStatus',
        caregiverId: payload.caregiverId,
        setBy: JSON.parse(localStorage.userData).id, /* current user id */
        data: payload.setActive ? {
          setActive: true,
        } : {
          employmentstatus: payload.employmentStatus === 'Inactive' ? payload.employmentStatus : 'Terminated',
          otherReason: payload.employmentStatus === 'Inactive' ? '' : (payload.employmentStatus !== 'Other:' ? payload.employmentStatus.split(' - ')[1] : payload.otherReason),
        },
      })
      formData.append('data', data)

      try {
        const res = await ctx.dispatch('request', formData)
        if (res.data.response.status === 200) {
          ctx.dispatch('popupNotif', {
            icon: 'success',
            title: 'Saved!',
            text: payload.setActive ? 'Activated succesfully.' : 'Deactivated succesfully.',
            showConfirmButton: false,
            timer: 1500,
          })
          if (router.currentRoute.params.caregiverId) {
            ctx.dispatch('refetchCaregiver') /* refetch caregiver info */
          } else {
            ctx.dispatch('getCaregivers') /* refetch caregivers */
          }
        }
      } catch (error) {
        // console.log(error)
      }
    },
    filterStatus: (ctx, payload) => {
      ctx.commit('SET_employmentstatus', payload)
      // eslint-disable-next-line no-underscore-dangle
      // eslint-disable-next-line no-nested-ternary
      const temp = payload
        ? payload !== 'Show All'
          // ? ctx.state.rawItems.filter(f => (payload === 'Active' ? ['Active', 'Applicant', 'New', 'Pending'].includes(f.employmentstatus) : ['Inactive', 'Resigned', 'Terminated'].includes(f.employmentstatus)))
          ? ctx.state.rawItems.filter(f => (payload === f.employmentstatus))
          : ctx.state.rawItems
        : ctx.state.rawItems

      ctx.commit('SET_ITEMS', temp)
    },

    request: (ctx, payload) => new Promise((resolve, reject) => {
      axios
        .post(Vue.prototype.$apiUrl2 + 'caregiver/action', payload, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    }),

    getCaregivers: ctx => {
      ctx.commit('SET_tableBusy', true)
      ctx.commit('SET_caregiver', {}) /* empty caregiver */

      const formData = new FormData()
      const data = JSON.stringify({
        action: 'get',
      })
      formData.append('data', data)

      ctx.dispatch('request', formData)
        .then(response => {
          // ctx.commit('SET_ITEMS', response.data.response.data.filter(f => (ctx.state.employmentstatus === '' ? f.employmentstatus === 'Active' : f.employmentstatus === ctx.state.employmentstatus)))
          ctx.commit('SET_ITEMS', response.data.response.data.filter(f => (ctx.state.employmentstatus === 'Show All' || ctx.state.employmentstatus === '' ? f : f.employmentstatus === ctx.state.employmentstatus)))
          ctx.commit('SET_RAW_ITEMS', response.data.response.data)
          ctx.commit('SET_ROWS', ctx.state.items.length)
          ctx.commit('SET_tableBusy', false)
        })
        .catch(error => {
          // console.log(error)
        })
    },

    refetchCaregiver: ctx => {
      const formData = new FormData()
      const data = JSON.stringify({
        action: 'getById1',
        id: router.currentRoute.params.caregiverId,
      })
      formData.append('data', data)

      axios
        .post(`${Vue.prototype.$apiUrl2}caregiver/action`, formData, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        })
        .then(response => {
          // console.log('response', response)
          ctx.commit('SET_caregiver', response.data.response.data.caregiver)
        })
        .catch(error => {
          // console.log(error)
        })
    },

    popupNotif: (ctx, payload) => {
      Swal.fire(payload)
    },
  },
}
