/* eslint-disable no-unused-vars */
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { debounce } from 'vue-debounce'

export default function userCaregiver() {
  // Use toast
  const toast = useToast()

  // const statusOptions = ref([
  //   'Active',
  //   'Applicant',
  //   'Inactive',
  //   'New',
  //   'Pending',
  //   'Resigned',
  //   'Terminated',
  // ])

  const statusOptions = ref([
    'Show All',
    // 'Active',
    // 'Inactive',
    'Active',
    //   'Applicant',
    'Inactive',
    //   'New',
    //   'Pending',
    'Resigned',
    'Terminated',
  ])

  const accountStatus = ref('Show All')

  // filters, search, pagination
  const pageOptions = [3, 5, 10]

  const perPage = ref(10)

  const totalRows = ref(0)

  const currentPage = ref(1)

  const filter = ref('')

  const sortBy = ref('')

  const sortDesc = ref('false')

  const sortDirection = ref('')

  const fields = ref(computed(() => store.state.caregiver.fields))

  const items = ref(computed(() => store.state.caregiver.items))

  const caregiver = ref({})

  const toastNotif = (title, text, icon, variant) => {
    toast({
      component: ToastificationContent,
      props: {
        title, text, icon, variant,
      },
    })
  }

  // addCaregiver
  const addCaregiver = caregiverData => {
    store.dispatch('caregiver/request', {
      action: 'add',
      data: caregiverData,
    })
      .then(() => {
        store.dispatch('caregiver/getCaregivers')
        toastNotif('Success', 'Added successfully!', 'CheckIcon', 'success')
      })
      .catch(error => {
        // console.log(error)
        toastNotif('Error', '', 'XIcon', 'danger')
      })
  }

  // updateCaregiver
  const updateCaregiver = caregiverData => {
    const formData = new FormData()

    const data = JSON.stringify({
      action: 'update',
      data: caregiverData.data,
    })
    formData.append('data', data)

    store.dispatch('caregiver/request', formData)
      .then(() => {
        // isCaregiverHandlerSidebarActive.value = false
        store.commit('caregiver/SET_isCaregiverHandlerSidebarActive', false)

        store.dispatch('caregiver/getCaregivers')
        // toastNotif('Success', 'Updated successfully!', 'CheckIcon', 'success')
        store.dispatch('caregiver/popupNotif', {
          icon: 'success',
          title: 'Saved!',
          text: 'Updated succesfully.',
          showConfirmButton: false,
          timer: 1500,
        })
      })
      .catch(error => {
        // console.log(error)
        // toastNotif('Error', '', 'AlertTriangleIcon', 'danger')
        store.dispatch('caregiver/popupNotif', {
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: false,
          timer: 1500,
        })
      })
  }

  /* Copy selected table row data to imputs and show sideBar inputs */
  // const editHandler = record => {
  //   caregiver.value = record
  //   // isCaregiverHandlerSidebarActive.value = true
  //   store.commit('caregiver/SET_isCaregiverHandlerSidebarActive', true)
  // }

  // const searchRequest = debounce(val => {
  //   store.commit('caregiver/SET_FILTERS', val)
  // }, '600ms')

  /* Filter the items based on selected status */
  watch(accountStatus, val => {
    store.dispatch('caregiver/filterStatus', val)
  })

  watch(totalRows, f => store.commit('caregiver/SET_ROWS', f))

  watch(currentPage, f => store.commit('caregiver/SET_CURR_PAGE', f))

  watch(perPage, f => store.commit('caregiver/SET_PERPAGE', f))

  watch(filter, qry => {
    // debugger
    store.commit('caregiver/SET_tableBusy', true)
    store.commit('caregiver/SET_FILTERS', qry)

    // searchRequest(f)
  })

  watch(items, f => store.commit('caregiver/SET_ROWS', f.length))

  store.dispatch('caregiver/getCaregivers')

  return {
    caregiver,
    addCaregiver,
    updateCaregiver,
    fields,
    items,
    accountStatus,
    statusOptions,
    perPage,
    pageOptions,
    totalRows,
    currentPage,
    sortBy,
    sortDesc,
    sortDirection,
    filter,
    // editHandler,
    toastNotif,
  }
}
