<template>
  <div>
    <b-table
      ref="table"
      hover
      responsive
      show-empty
      class="position-relative"
      :per-page="$store.state.caregiver.perPage"
      :current-page="$store.state.caregiver.currentPage"
      :items="caregivers"
      :busy="$store.state.caregiver.tableBusy"
      :fields="$store.state.caregiver.fields"
      :sort-by.sync="$store.state.caregiver.sortBy"
      :sort-desc.sync="$store.state.caregiver.sortDesc"
      :sort-direction="$store.state.caregiver.sortDirection"
      :filter="$store.state.caregiver.filter"
      :filter-included-fields="$store.state.caregiver.filterOn"
      empty-text="No matching records found"
      @row-clicked="editHandler"
      @filtered="onFiltered"
    >
      <!-- Loading Spinner  -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner
            type="grow"
            class="align-middle"
            variant="primary"
          />
          <strong> Loading...</strong>
        </div>
      </template>

      <!-- Header  -->
      <template #head()="data">
        <span>{{ data.label }}</span>
      </template>

      <!-- Id  -->
      <template #cell(id)="data">
        <span class="text-nowrap">
          <b-link
            class="font-weight-bold"
          >
            #{{ data.value }}
          </b-link>
        </span>
      </template>

      <!-- Name  -->
      <template #cell(lastname)="data">
        <div class="text-nowrap">
          <b-avatar
            size="42"
            :src="data.item.profilepicture ? `${url}upload/${data.item.id}/${data.item.profilepicture}` : ''"
            :variant="['Teminated', 'Inactive','Resigned'].includes(data.item.employmentstatus) ? 'danger' : 'primary' "
            :text="avatarText(`${data.item.firstname.slice(0, 1)} ${data.item.lastname.slice(0, 1)}`)"
            rounded
          />
          <span
            class="ml-1 text-primary"
          >
            {{ data.item.lastname }},
            {{ data.item.firstname }}
          </span>
        </div>
      </template>

      <!-- Hire Date  -->
      <template #cell(hiredate)="data">
        <span class="text-nowrap">
          {{ formatHiredDate(data.item.hiredate) }}
        </span>
      </template>

      <!-- Emailaddress  -->
      <template #cell(emailaddress)="data">
        <span class="text-nowrap">
          {{ data.item.emailaddress }}
        </span>
      </template>

      <!-- Contact Number  -->
      <template #cell(contact_number)="data">
        <span class="text-nowrap">
          <span v-if="data.item.phonenumber !== ''">
            W: {{ data.item.phonenumber }}
          </span>
          <span v-else-if="data.item.mobilenumber !== ''">
            M: {{ data.item.mobilenumber }}
          </span>
          <span v-else-if="data.item.faxnumber !== ''">
            F: {{ data.item.faxnumber }}
          </span>
        </span>

      <!-- <span v-if="data.item.phonenumber != ''">
        P: {{ data.item.phonenumber }} <br>
      </span>
      <span v-if="data.item.mobilenumber != ''">
        M: {{ data.item.mobilenumber }} <br>
      </span>
      <span v-if="data.item.faxnumber != ''">
        F: {{ data.item.faxnumber }}
      </span> -->
      <!-- <div class="text-nowrap">
        {{ [data.item.phonenumber, data.item.mobilenumber, data.item.faxnumber].filter(Boolean).join(', ') }}
      </div> -->
      </template>

      <!-- Column address -->
      <template #cell(location)="data">
        <!-- <span class="text-nowrap"> -->
        <span
          v-if="data.item.location == 'N/A'"
        >N/A</span>
        <span
          v-for="loc in data.item.location"
          v-else
          :key="loc"
        >
          {{ loc }}
          <br>
        </span>

      </template>

      <!-- Employment status -->
      <template #cell(employmentstatus)="data">
        <span class="text-nowrap status-badge">
          <b-badge
            v-if="['Active', 'Applicant', 'New', 'Pending'].includes(data.item.employmentstatus)"
            class="active"
          >
            Active
          </b-badge>
          <b-badge
            v-else
            class="inactive"
          >
            {{ data.item.employmentstatus }}
          </b-badge>
        </span>
      </template>

      <!-- Column Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <!-- Dropwdown Item Edit -->
            <b-dropdown-item
              @click="$store.commit('caregiver/SET_caregiver' ,data.item); $store.commit('caregiver/SET_isCaregiverHandlerSidebarActive', true)"
            >
              <feather-icon
                :id="`id=${data.item.id}`"
                icon="EditIcon"
              />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <!-- Dropwdown Item Delete -->
            <b-dropdown-item>
              <div
                v-if="['Active', 'Applicant', 'New', 'Pending'].includes(data.item.employmentstatus)"
                v-b-modal.modal-deactivate
                @click="tempUserData = data.item"
              >
                <feather-icon
                  :id="`id=${data.item.id}`"
                  icon="ToggleLeftIcon"
                />
                <span class="align-middle ml-50">Deactivate</span>
              </div>
              <div
                v-else
                v-b-modal.modal-activate
                @click="tempUserData = data.item"
              >
                <feather-icon
                  :id="`id=${data.item.id}`"
                  icon="ToggleRightIcon"
                />
                <span class="align-middle ml-50">Activate</span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

      <!-- Rating  -->
      <template #cell(rating)="data">
        <b-form-rating
          v-model="data.item.rating"
          variant="warning"
          no-border
          readonly
        />
      </template>
    </b-table>

    <!-- activate modal  -->
    <b-modal
      id="modal-activate"
      title="Activate"
      centered
    >
      <h5 class="my-3">
        You are about to activate caregiver account <b>{{ `${tempUserData.firstname} ${tempUserData.lastname}` }}</b>
      </h5>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-dark"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          @click="$store.dispatch('caregiver/setCaregiverStatus', {setActive : true, caregiverId: tempUserData.id}); ok()"
        >
          Activate
        </b-button>
      </template>
    </b-modal>
    <!-- deactivate modal  -->
    <b-modal
      id="modal-deactivate"
      title="Deactivate"
      centered
    >
      <div class="mx-0.5 my-1">
        <span>
          You are about to deactivate the caregiver account of <b>{{ `${tempUserData.firstname} ${tempUserData.lastname}` }}</b>.<br>
          Select the reason for deactivation below:
        </span>
        <b-form-group class="mt-1">
          <b-form-radio-group
            v-model="employmentStatus"
            :options="['Inactive','Terminated - Quit','Terminated - for Cause', 'Terminated - Insufficient Work Available', 'Other:']"
            name="radios-stacked"
            stacked
          />
        </b-form-group>
        <b-form-textarea
          v-if="employmentStatus === 'Other:'"
          v-model="otherReason"
          rows="2"
        />
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-dark"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="danger"
          :disabled="employmentStatus === null"
          @click="$store.dispatch('caregiver/setCaregiverStatus', {setActive : false, employmentStatus: employmentStatus, caregiverId: tempUserData.id, otherReason: otherReason}); employmentStatus = null; ok()"
        >
          Deactivate
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BFormRating,
  BDropdown,
  BDropdownItem,
  BLink,
  BSpinner,
  BBadge,
  BModal,
  BButton,
  BFormRadioGroup,
  BFormGroup,
  BFormTextarea,

} from 'bootstrap-vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import Vue from 'vue'
import router from '@/router'
import { avatarText } from '@core/utils/filter'
import { ref, computed } from '@vue/composition-api'

export default {
  components: {
    BTable,
    BAvatar,
    BFormRating,
    BDropdown,
    BDropdownItem,
    BLink,
    BSpinner,
    BBadge,
    BModal,
    BButton,
    BFormRadioGroup,
    BFormGroup,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  setup() {
    const formatHiredDate = date => moment(date, 'YYYY-MM-DD').format('LL')

    // Create an options list from our fields

    const editHandler = record => {
      // store.commit('caregiver/SET_isCaregiverHandlerSidebarActive', true)
      // store.commit('caregiver/SET_caregiver', record)
      // console.log('record', record)
      router.push({ name: 'caregiver-view', params: { caregiverId: record.id } })
    }

    /* Searched items */
    const onFiltered = filteredItems => {
      store.commit('caregiver/SET_ROWS', filteredItems.length)
      store.commit('caregiver/SET_CURR_PAGE', 1)
      store.commit('caregiver/SET_tableBusy', false)
    }
    const tempUserData = ref({}) /* used by activate/deactivate employee status */

    const employmentStatus = ref(null) /* used by activate/deactivate employee status */
    // const getLocation = (caregiverId) => {
    //   store.state.caregiverShift.shifts.filter(shift => caregiverId === shift.ca && moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').isBetween(moment(shift.start, 'YYYY-MM-DD'), moment(shift.end, 'YYYY-MM-DD')))
    //     .map(f => f.location)
    // }
    const caregivers = ref(computed(() => store.state.caregiver.items))
    const table = ref(null)

    const refreshTable = () => table.value.refresh()
    return {
      onFiltered,
      url: Vue.prototype.$mainUrl,

      caregivers,
      formatHiredDate,
      editHandler,
      tempUserData,
      employmentStatus,
      otherReason: ref(''),
      // getLocation,

      avatarText,
      table,
      refreshTable,
    }
  },
}
</script>

<style lang="scss" scoped>
  [dir] .status-badge {
    .active{
      // background: #E0F7FA !important;
      // color: #01BCD6 !important;
      font: normal normal bold 12px/10px Montserrat !important;
    }
    .inactive{
      background: #DCE4EE !important;
      // color: #516F90 !important;
      font: normal normal bold 12px/10px Montserrat !important;
    }
  }
  [dir] .modal-body{
    text-align: left;
    /* font: normal normal normal 14px/24px Montserrat; */
    letter-spacing: 0px;
    // color: #33475B !important;
    .text-bold{
      text-align: left;
      font: normal normal 600 14px/24px Montserrat;
      letter-spacing: 0px;
      // color: #33475B;
    }
  }
[dir] .table-hover tbody td {
  .text-primary, .text-nowrap{
    font: normal normal normal 14px/20px Montserrat !important;
  }
}
</style>
